import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const PageHero = loadable(() => import("@organisms/PageHero"));
const PageBuilder = loadable(() => import("@templates/PageBuilder"));
const EopCta = loadable(() => import("@organisms/EopCta"));
const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));

const PagePage = ({ data }) => {
  const { hero, pageBuilder, hideCta, otherCta, hideNewsletter } = data;
  return (
    <PageContainer overflow>
      {!!hero.heading && <PageHero {...hero} />}
      <PageBuilder
        blocks={pageBuilder}
        hasHero={!!hero.heading}
        noHeight={!!hero.noHeight}
      />
      {!hideCta && (
        <div className="relative mt-auto w-full">
          <EopCta otherCta={otherCta} />
          {hideNewsletter && (
            <div className="absolute left-0 right-0 bottom-0 h-[50%] bg-black" />
          )}
        </div>
      )}
      {!hideNewsletter && <NewsletterSignup />}
    </PageContainer>
  );
};

export default PagePage;
